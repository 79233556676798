.sv-tasks-portlet .sv-fn-tasks {
   position:relative
}

.sv-tasks-portlet .sv-fn-tasks, .sv-tasks-portlet .sv-fn-tasks * {
   box-sizing:border-box;
}

form.sv-fn-event-edit.form-horizontal .control-group {
   margin-bottom: 10px;
}

.sv-tasks-portlet .sv-fn-tasks .sv-typeselector-button {
   margin-top: 1px;
   margin-right: 1px;
   float: right;
}

.sv-tasks-portlet .close {
   margin: 0;
}

.sv-tasks-portlet .popover-title {
   line-height: 25px
}

.sv-tasks-portlet ol {
   list-style:none;
   margin:0 0 10px 0;
   padding:0
}

.sv-tasks-portlet .sv-task-overdue {
   color:red
}

.sv-tasks-portlet .input-append input.sv-tasks-input {
   height:20px;
   width: 80%;
   box-sizing:content-box;
}

.sv-tasks-portlet .input-append button {
   height: 30px;
}

.sv-tasks-portlet .input-prepend .add-on {
   height: 20px !important;
   margin-top: 2px
}

.sv-no-touch .sv-tasks-portlet .sv-task:hover {
   background-color:#F5F5F5;
   border-radius:4px;
   box-shadow:inset 0 1px 1px #999
}

.sv-tasks-portlet li.sv-task {
   display: block;
   padding:5px 10px;
   border-radius:4px;
   width:100%;
   background-color: #F5F5F5;
   border: 1px solid #ECECEC;
   margin-top: 5px;
}

.sv-tasks-portlet .sv-task-wrapper {
   display: table;
   table-layout:fixed;
   width:100%;
}

.sv-tasks-portlet .sv-task-link {
   cursor:pointer;
   text-decoration:none;
   display:table-cell;
   width:100%;
   padding-left: 25px;

}

.sv-tasks-portlet .sv-task-clock-cell {
   display:table-cell;
   width:20px;
   padding-left: 10px;
}

.sv-tasks-portlet .bootstrap input.sv-task-toggle {
   margin-right:6px;
   display:table-cell;
   width:20px
}

.sv-tasks-portlet .sv-task-text-container {
   display:inline-block;
   border-left:1px solid #D0D0D0;
   margin-left:20px;
   padding-left:20px;
   padding-bottom:10px
}

.sv-tasks-portlet .sv-tasks-functions {
   padding-top: 1em;
   margin-top: 1em;
   border-top: 1px solid #eee
}

.sv-tasks-portlet .sv-tasks-functions a {
   text-decoration:none;
}

.sv-tasks-portlet .sv-tasks-functions a:hover {
   text-decoration:underline;
}

.sv-tasks-portlet .sv-percentDone {
   color:#999
}

.sv-tasks-portlet .sv-more{
   cursor:pointer;
   font-size:smaller;
   font-weight:bold
}
.sv-tasks-portlet .sv-show-scroll {
   overflow:auto;
   position:relative;
   margin-right: -14px;
}

.sv-tasks-portlet .sv-task-user-icon {
   vertical-align: middle !important;
}


.sv-tasks-portlet .sv-task-title {
   word-wrap: break-word;
}

.sv-tasks-portlet .sv-task-title:hover {
   text-decoration:none
}

.sv-tasks-portlet .sv-task-title-owner:hover {
   text-decoration:underline;
}
